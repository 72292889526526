import React from 'react'
import { connect } from 'react-redux'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import { UnderlinedButton, Tabs } from '../components'
import Basket from '../images/basket.png'
import FbReview from '../images/fb_reviews.png'
import GoogleReview from '../images/google_review.png'
import Slash from '../images/slash.png'
import GooglePlayLink from '../images/play-store-link.png'
import AppStoreLink from '../images/app-store-link.png'
import Images from '../images'
import { getAdminSettings } from '../services/AdminSettings'
import { getNovaPonuda, getPopularniProizvodi } from '../store/Actions/Products'
import {
  getNovaPonudaSelector,
  getPopularniProizvodiSelector,
} from '../store/Selectors/Products'
import { GOOGLE_PLAY_LINK, APP_STORE_LINK } from '../constants'

class Home extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      adminSettings: null,
      displayStoreLinks: true
    }
  }

  async componentDidMount() {
    const adminSettingsResult = await getAdminSettings()
    if (adminSettingsResult.isOk) {
      this.setState({ adminSettings: adminSettingsResult.data })
    }

    // Call novaPonuda and Popular to store in redux - use it in selectors
    this.props.getNovaPonuda()
    this.props.getPopularniProizvodi()
  }

  render() {
    const dostavaSarajevo = this.state.adminSettings?.dostava?.sarajevo
    const dostavaZenica = this.state.adminSettings?.dostava?.zenica
    const dostavaDrugiGradovi = this.state.adminSettings?.dostava?.drugiGradovi
    const napomena = this.state.adminSettings?.config?.napomena[0]?.Vrijednost

    return (
      <>
        <Navbar />
        <section className="section" id="homeWelcomeSection">
          <div className="container">
            <img
              src={Basket}
              className="basket-image"
              alt="Basket background"
            />
            <div className="left">
              <div className="left-box">
                <h1 className="title">
                  DOBRODOŠLI U<br />
                  KORPU ZDRAVLJA!
                </h1>
                <p>{`Kupite najbolje domaće proizvode jednostavno preko interneta. Uvijek svježe. Na kućni prag.`}</p>
                {napomena && <p className="has-text-weight-bold">{napomena}</p>}
                <div className="buttons">
                  <UnderlinedButton
                    txt="Započni narudžbu"
                    linkTo="/shop"
                    isGreen={true}
                    isPurple={true}
                  />

                  <UnderlinedButton
                    txt="Više o Korpi zdravlja"
                    linkTo="#zastoKorpaSection"
                    isTransparent={true}
                    handleClick={() =>
                      document
                        .getElementById('zastoKorpaSection')
                        .scrollIntoView({ behavior: 'smooth' })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="reviews">
              <img src={FbReview} className="review" alt="Facebook Review" />
              <img src={Slash} className="review" alt="Slash" />
              <img src={GoogleReview} className="review" alt="Google Review" />
            </div>
          </div>
        </section>

        <section className="section" id="zastoKorpaSection">
          <div className="container">
            <div className="subtitleDiv">
              <h2 className="title has-text-centered">ZAŠTO KORPA ZDRAVLJA?</h2>
            </div>

            <div className="columns is-multiline">
              <div className="column is-4">
                <div>
                  <img src={Images.Illustration1} alt="Illustration" />
                  <p className="has-text-centered mt-5">{`Bolji ukus i više nutrijenata za tebe i tvoju porodicu. Prirodna hrana za zdravo tijelo i duh.`}</p>
                </div>{' '}
              </div>
              <div className="column is-4">
                <div>
                  <img src={Images.Illustration2} alt="Illustration" />
                  <p className="has-text-centered mt-5">{`Najveći online izbor prirodnih namirnica u BiH.`}</p>
                </div>{' '}
              </div>
              <div className="column is-4">
                <div>
                  <img src={Images.Illustration3} alt="Illustration" />
                  <p className="has-text-centered mt-5">{`Pouzdana dostava širom BiH. Sa sela na tvoj kućni prag, čak i na zadnji sprat.`}</p>
                </div>{' '}
              </div>
              <div className="column is-4">
                <div>
                  <img src={Images.Illustration4} alt="Illustration" />
                  <p className="has-text-centered mt-5">{`Porodični smo biznis koji godinama usavršava proizvode i metode prirodnog uzgoja. Radimo ono sto najviše volimo.`}</p>
                </div>{' '}
              </div>
              <div className="column is-4">
                <div>
                  <img src={Images.Illustration5} alt="Illustration" />
                  <p className="has-text-centered mt-5">{`Vođeni osnovnim moralnim principima brinemo o našim kupcima, koje doživljavamo kao širu porodicu.`}</p>
                </div>{' '}
              </div>
              <div className="column is-4">
                <div>
                  <img src={Images.Illustration6} alt="Illustration" />
                  <p className="has-text-centered mt-5">{`Vjerujemo u  ljepše sutra. Kupovinom podržavate male proizvođače u BiH i brinete o ekologiji naše zemlje i planete.`}</p>
                </div>{' '}
              </div>
            </div>

            <div className="shop-button">
              <UnderlinedButton
                txt="Započni narudžbu"
                linkTo="/shop"
                isOrange={true}
              />
            </div>
          </div>
        </section>

        <section className="section" id="kakoFunkcionisuNarudzbe">
          <div className="container">
            <div className="subtitleDiv">
              <h2 className="subtitle">
                KAKO FUNKCIONIŠU
                <br />
                NARUDŽBE?
              </h2>
            </div>

            <div className="columns">
              <div className="column is-3">
                <div>
                  <img
                    src={Images.KakoFunkcionisu1}
                    alt="Kako funkcionisu narudzbe?"
                  />
                  <p className="has-text-centered">01 - Naručite u shop-u</p>
                </div>
              </div>
              <div className="column is-3">
                <div>
                  <img
                    src={Images.KakoFunkcionisu2}
                    alt="Kako funkcionisu narudzbe?"
                  />
                  <p className="has-text-centered">02 - Beremo i pakujemo</p>
                </div>
              </div>
              <div className="column is-3">
                <div>
                  <img
                    src={Images.KakoFunkcionisu3}
                    alt="Kako funkcionisu narudzbe?"
                  />
                  <p className="has-text-centered">
                    03 - Dostavljamo na kućni prag
                  </p>
                </div>
              </div>
              <div className="column is-3">
                <div>
                  <img
                    src={Images.KakoFunkcionisu4}
                    alt="Kako funkcionisu narudzbe?"
                  />
                  <p className="has-text-centered">
                    04 - Povrat ambalaže dostavljaču
                  </p>
                </div>
              </div>
            </div>

            <div className="subtitleDiv">
              <h3 className="subtitle">Instrukcije za pojedinačne regije</h3>
            </div>

            <Tabs>
              <div label="Sarajevo, Ilijaš i Visoko">
                {dostavaSarajevo?.length > 0 &&
                  dostavaSarajevo.map((dostava, index) => {
                    return (
                      dostava?.Vrijednost && (
                        <p key={index}>{dostava?.Vrijednost}</p>
                      )
                    )
                  })}
              </div>
              <div label="Zenica, Kakanj">
                {dostavaZenica?.length > 0 &&
                  dostavaZenica.map((dostava, index) => {
                    return <p key={index}>{dostava?.Vrijednost}</p>
                  })}
              </div>
              <div label="Ostatak BiH">
                {dostavaDrugiGradovi?.length > 0 &&
                  dostavaDrugiGradovi.map((dostava, index) => {
                    return <p key={index}>{dostava?.Vrijednost}</p>
                  })}
              </div>
            </Tabs>

            <div className="shop-button">
              <UnderlinedButton
                txt="Započni narudžbu"
                linkTo="/shop"
                isGreen={true}
              />
            </div>
          </div>
        </section>

        <section className="section" id="nasaPrica">
          <div className="container">
            <div className="columns">
              <div className="column is-6">
                <h3>Zašto naše namirnice?</h3>
                <p>
                  Mi vraćamo namirnicama davno izgubljeni miris, okus i
                  kvalitet kroz prirodne metode
                  uzgoja hrane u ekološki prihvatljivom okruženju. Sigurna veza
                  sa malim proizvođačima. Namirnice proizvedene sa mnogo ljubavi
                  i požrtvovanosti. Hranu koju proizvodimo za Vas i sami jedemo.
                  Naša hrana je bogatija vitaminima i mineralima zbog samog
                  načina uzgoja.
                </p>
              </div>
              <div className="column is-6">
                <img
                  src={Images.ZastoNaseNamirnice}
                  alt="Zasto nase namirnice?"
                />
              </div>
            </div>

            <div className="columns">
              <div className="column is-6">
                <img src={Images.NasaPrica} alt="Zasto nase namirnice?" />
              </div>
              <div className="column is-6">
                <h3>Naša priča</h3>
                <p>
                  Dobro nam došli! <br />
                  Mi smo ekipa mladih ljudi, sa sela Bradve. <br />
                  Sve je počelo u julu 2016. godine kada smo pokrenuli platformu “Korpa zdravlja”, njen zadatak je bio da nas poveže sa Vama. <br />
                  Uzgajamo zdravu hranu, prirodnim metodama koje smo sami razvili kroz dugogodišnje iskustvo. <br />
                  Naši najveći motivi su zdravlje i ekologija naše zemlje. Hvala Vam što podržavate naš rad i razvijate zdraviju svijest i zajednicu!
                </p>
              </div>
            </div>
          </div>
        </section>

        {this.state.displayStoreLinks ? <div className='storeLinksDiv'>
          <img onClick={() => { window.location.href = GOOGLE_PLAY_LINK }} src={GooglePlayLink} className="" alt="Google play link" />
          <img onClick={() => window.location.href = APP_STORE_LINK } src={AppStoreLink} className="" alt="App Store link" />
          <span onClick={() => this.setState({ displayStoreLinks: false })}>x</span>
        </div> : null}
        
        <Footer />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  token: state.authentication,
  novaPonuda: getNovaPonudaSelector(state),
  popularniProizvodi: getPopularniProizvodiSelector(state),
  productsInCart: state.products.productsInCart,
})

const mapDispatchToProps = {
  getPopularniProizvodi,
  getNovaPonuda,
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
